import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme, StatusBadge, NakedButton, ChevronIcon, BarChartIncreaseIcon, SpoilerAlertAppleIcon } from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import routePaths from '../../route-paths';
import OfferReviewTrucklaneCard from './offer-review-trucklane-card';

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  spacedRow: {
    extend: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  marginRow: {
    extend: 'row',
    marginBottom: 12,
    flexWrap: 'wrap',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.18px',
  },
  viewTruckLane: {
    color: Theme.textColorPrimary,
    background: 'none',
    fill: Theme.textColorPrimary,
    fontSize: '16px',
    lineHeight: '0.75',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    paddingRight: '24px',
    color: Theme.tableHeaderTextColor,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: 1.33,
  },
  value: {
    paddingRight: '24px',
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
  },
  arrow: {
    height: 8,
    width: 16,
    stroke: Theme.textColorPrimary,
    marginRight: 8,
  },
  chart: {
    marginRight: 8,
    transform: 'translateY(-4px)',
  },
  opportunityText: {
    color: Theme.green,
    fontSize: 14,
    fontWeight: 500,
  },
  opportunityRowExpand: {
    extend: 'row',
    padding: 8,
    marginBottom: 4,
    width: 'fit-content',
    '&:hover': {
      backgroundColor: Theme.green5,
      cursor: 'pointer',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    marginRight: 12,
    alignItems: 'center',
  },
  spoilerAlertApple: {
    height: 18,
    width: 16,
  },
});

const exportStatusStringMap = {
  IN_PROGRESS: 'ACCEPTANCE IN PROGRESS',
  COMPLETE: 'ACCEPTED',
  NOT_IN_PROGRESS: 'NOT ACCEPTED',
  STARTED: 'PENDING',
};

const OfferReviewBuyerCard = ({ buyerData, status, columns, shiftSuggestions, hasShiftSuggestions, history, user, setShowActivatedLaneBanner }) => {
  const classes = useStyles();
  const [showSuggestions, setShowSuggestions] = useState(false);

  const opportunityAmount = useMemo(() => {
    if (hasShiftSuggestions)
      return accounting.formatMoney(
        shiftSuggestions.trucklanes.reduce((acc, current) => {
          const trucklaneTotal = current.originalOfferListings.reduce((olAcc, olCurrent) => {
            const offerShiftTotal = olCurrent.offerShifts.reduce(
              (shiftAcc, shiftCurrent) => shiftAcc + (shiftCurrent.status === 'PROPOSED' ? shiftCurrent.shiftedQuantity * shiftCurrent.unitPrice : 0),
              0
            );
            return olAcc + offerShiftTotal;
          }, 0);
          return acc + trucklaneTotal;
        }, 0)
      );
    return 0;
  }, [shiftSuggestions, hasShiftSuggestions]);

  const rowNavigation = (row) => {
    history.push(`${routePaths.offerReview}/${row.siteId}/${encodeURIComponent(row.siteName)}`);
  };

  return (
    <div>
      <div className={classes.spacedRow}>
        <div className={classes.row}>
          <div className={classes.titleContainer}>
            <span className={classes.title}>{buyerData.siteName}</span>
            {buyerData.isVendorOfRecord && <SpoilerAlertAppleIcon className={classes.spoilerAlertApple} />}
          </div>
          <StatusBadge status={exportStatusStringMap[status] || exportStatusStringMap[buyerData.exportStatus]} key="status" />
        </div>
        {exportStatusStringMap[status] === exportStatusStringMap.NOT_IN_PROGRESS && (
          <NakedButton secondary className={classes.viewTruckLane} loading={false} onClick={() => rowNavigation(buyerData)}>
            View Active Truck Lanes
          </NakedButton>
        )}
      </div>
      <div className={classes.marginRow}>
        {columns.map((col) => {
          return (
            <div key={col.displayName} className={classes.column}>
              <span className={classes.header}>{col.displayName}</span>
              <span className={classes.value}>{col.formatter ? col.formatter(buyerData[`${col.field}`]) : buyerData[`${col.field}`]}</span>
            </div>
          );
        })}
      </div>
      {hasShiftSuggestions && exportStatusStringMap[status] === exportStatusStringMap.NOT_IN_PROGRESS && (
        <div className={classes.opportunityRow}>
          <div className={classes.opportunityRowExpand} onClick={() => setShowSuggestions(!showSuggestions)}>
            <ChevronIcon
              className={classes.arrow}
              style={{ transform: `rotate(${showSuggestions ? '180' : '0'}deg)`, transition: 'transform 0.3s' }}
            />
            <div>
              <BarChartIncreaseIcon className={classes.chart} />
              <span className={classes.opportunityText}>
                {showSuggestions ? 'HIDE' : 'SHOW'} {opportunityAmount} OPPORTUNITY
              </span>
            </div>
          </div>
        </div>
      )}
      {showSuggestions && exportStatusStringMap[status] === exportStatusStringMap.NOT_IN_PROGRESS && (
        <div>
          {shiftSuggestions.trucklanes.map((trucklane) => {
            return (
              <OfferReviewTrucklaneCard
                key={trucklane._id}
                trucklane={trucklane}
                buyerName={buyerData.siteName}
                user={user}
                setShowActivatedLaneBanner={setShowActivatedLaneBanner}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

OfferReviewBuyerCard.propTypes = {
  buyerData: PropTypes.object,
  status: PropTypes.string,
  columns: PropTypes.array,
  shiftSuggestions: PropTypes.object,
  hasShiftSuggestions: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
  setShowActivatedLaneBanner: PropTypes.func,
};

export default OfferReviewBuyerCard;
